import { commonUtil } from "./CommonUtils";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ApiResponse, ServerApiResponse, ServerError } from "../../wisdome";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (401 === err.response.status) {
      localStorage.clear();
      window.location.href = "/login";
    } else if (
      500 === err.response.status &&
      err.response.data?.includes("jwt expired")
    ) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      return err;
    }
  }
);

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}

const BASEURL = commonUtil.BASEURL();
if (!BASEURL) throw new Error("BASEURL is not defined");

let register = async (data) => {
  await axios
    .post(BASEURL + "user/register", data)
    .then((res) => {
      return res;
    })
    .catch((error) => console.log(error));
};

function verifyAccount(otp) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const obj = {
    email: localStorage.getItem("email"),
    otp: otp,
  };

  return axios
    .post(BASEURL + "subadmin/otpverify", obj, configData)
    .then((res) => {
      if (res.data.result.status == 200) {
        localStorage.setItem("is_verify", "true");
        commonUtil.redirect("/3-step");
      } else {
        alert("Invalid OTP");
      }
    });
}

let subadminregister = async (data): Promise<ApiResponse> => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };

  var formData = new FormData();
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("businessName", data.businessName);
  formData.append("email", data.email);
  formData.append("phoneNumber", data.phoneNumber);
  formData.append("password", data.password);
  formData.append("membershipName", data.membershipName);
  formData.append("image", data.image);
  formData.append("brandColor", data.brandColor);
  formData.append("countryCode", data.countryCode);

  let res = await axios.post<ServerApiResponse>(
    BASEURL + "subadmin/register",
    formData,
    config
  );
  if (res.status === 200) {
    if (res.data?.result?.success) {
      localStorage.setItem("email", res.data.result?.user.email);
      localStorage.setItem("token", res.data?.result.token as string);
      localStorage.setItem("image", res.data.result?.user.image);
      localStorage.setItem("user_id", res.data.result?.user._id);
      localStorage.setItem("pabblyUser_id", res.data.result?.user.pabblyUserId);
      localStorage.setItem("bgcolor", res.data.result?.user.brandColor);
      localStorage.setItem(
        "membershipName",
        res.data.result?.user.membershipName
      );
    }
    const response: ApiResponse = {
      data: res.data,
      message: res.data?.result?.success
        ? "Registration successful"
        : res.data?.result?.message || "Registration failed",
      isSuccess: res.data?.result?.success || false,
    };
    return response;
  } else {
    console.log("registration error", res);
    const response: ApiResponse = {
      message: "Registration failed",
      isSuccess: false,
    };

    if (isAxiosError(res)) {
      let serverMessage = (res.response?.data as ServerError)?.body;
      response.message = serverMessage || "Registration failed";
    }

    return response;
  }
};

let login = async (data) => {
  let currentDate = new Date();
  let minutesAdd = currentDate.getMinutes() + 30;
  let hoursAdd = currentDate.getHours();
  if (minutesAdd >= 60) {
    hoursAdd = hoursAdd + 1;
    minutesAdd = minutesAdd - 60;
  }

  await axios
    .post(BASEURL + "subadmin/login", data)
    .then(async (res) => {
      localStorage.setItem("email", res.data.result.email);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("image", res.data.result.image);
      localStorage.setItem("user_id", res.data.result._id);
      localStorage.setItem("pabblyUser_id", res.data.result.pabblyUserId);
      localStorage.setItem("bgcolor", res.data.result.brandColor);
      localStorage.setItem("membershipName", res.data.result.membershipName);
      if (res?.data?.result?.subscriptionId[0]) {
        localStorage.setItem(
          "membershipLevel",
          res.data.result.subscriptionId[0].subscriptionAllowed
        );
      }
      localStorage.setItem("is_verify", "true");

      if (res.status === 200) {
        if (res.data.result.status === "Active") {
          commonUtil.redirect("/dashboard");
        } else {
          const data1 = {
            email: res.data.result.email,
          };
          await axios.post(BASEURL + "subadmin/verify", data1).then((res1) => {
            commonUtil.redirect("/verify-otp");
          });
        }
      } else {
        commonUtil.redirect("/login");
      }
    })
    .catch((error) => {
      alert("Invalid login Credentials");
      // commonUtil.redirect("/home")
      console.log(error.message);
    });
};

function completeSubscription(subAdminId, hostedPage) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .post(
      BASEURL + "subadmin/completesubscription",
      { subAdminId: subAdminId, hostedpage: hostedPage },
      configData
    )
    .then((res) => {
      console.log("completeSubscription", res);
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch(async (error) => {
      console.log("An error occurred while completing subscription!", error);
    });
}

function canSubscribe(planId) {
  if (!planId || typeof planId !== "string")
    return Promise.reject("Invalid planId");

  const bearer = "Bearer " + localStorage.getItem("token");
  const configData: AxiosRequestConfig = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + `sasubscription/canSubscribe/${planId}`, configData)
    .then((res) => res.data);
}

// http://localhost:9000/api/subadmin/getSubadmin/615437b9cad3e8474069a98f

function userInfo(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  //return axios.get(BASEURL + 'socialwall/allusers/' + userId, configData).then(res => res.data)
  return axios
    .get(BASEURL + "subadmin/getSubadmin/" + userId, configData)
    .then((res) => {
      res.data.result.validUser.subscriptionId.length > 0 ??
        localStorage.setItem(
          "membershipLevel",
          res.data.result.validUser.subscriptionId[0].subscriptionAllowed
        );
      if (
        res.data.result.validUser.company &&
        res.data.result.validUser.company.length > 0 &&
        res.data.result.validUser.company[0].logo
      ) {
        localStorage.setItem(
          "image",
          res.data.result.validUser.company[0].logo
        );
      } else {
        localStorage.setItem("image", res.data.result.validUser.image);
      }

      return res.data;
    });
}

function userInfo1(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/allusers/" + userId, configData)
    .then((res) => res.data);
  // return axios.get(BASEURL + 'subadmin/getSubadmin/' + userId, configData).then(res => res.data)
}
// " http://localhost:9000/api/socialwall/allusers/61891436860927355333961d "
// subadmin/getSubadmin/

function subscription(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId1 = localStorage.getItem("user_id");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + userId1, configData)
    .then((res) => res.data);
}

function sasubscription(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId1 = localStorage.getItem("user_id");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "sasubscription", configData).then((res) => {
    let CompareforSub = localStorage.getItem("CompareforSub");

    res.data.result.subscriptions.forEach((item) => {
      if (item.planName === CompareforSub) {
        localStorage.setItem("membershipLevel", item.membersAllowed);
      }
    });

    return res.data;
  });
}

function createPaypalTransaction(datas) {
  let pabbly_user_id = localStorage.getItem("pabblyUser_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .post(BASEURL + "subadmin/createPaypalTransaction", datas, configData)
    .then((res) => res.data);
}

let updateProfile = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");

  const companyInfo = {
      name: data.company.name,
      city: data.company.city,
      address: data.company.address,
      country: data.company.country,
      email: data.company.email,
      websiteURL: data.company.websiteURL,
      contactNumber: data.company.contactNumber,
      countryCode:data.company.countryCode,
  };

  var formData = new FormData();
  formData.append("role", data.role);
  if (data.image) {
    formData.append("image", data.image);
  }
  formData.append("name", data.name);

  // http://localhost:9000/api/subadmin/61388b29286e87f188bd17a8

  await axios
    .put(BASEURL + "subadmin/company/" + userId, companyInfo, config)
    .then(async (res) => {
      await axios
        .put(BASEURL + "subadmin/" + userId, formData, config)
        .then(async (res1) => {
          //console.log(res);
        });

      if (res.status === 200) {
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => console.log(error));

  // uploadImage
  //   var formData = new FormData();
  //   formData.append('role', data.role);
  //   formData.append('image', data.image);
  //   formData.append('company', data.company);
  //   await axios.put(BASEURL + "subadmin/"+userId, formData,config).then(async (res) => {
  //   if(res.status===200){
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // }
  // ).catch((error) => console.log(error))
};

let createFolder = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("subtitle", data.sub_title);
  formData.append("schedule", data.dates);
  formData.append("image", data.img);
  formData.append("scheduleFolderSwitch", data.scheduleFolderSwitch);
  formData.append("expiryDateSwitch", data.expiryDateSwitch || false);
  formData.append("expiryDate", data.expiryDate || '');

  //formData.append('memberAccessLevel', data.memberAccessLevel);
  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("source", data.source);
  formData.append("contains", data.contains);

  return axios.post(BASEURL + "folder/createFolder", formData, config);
};

let createSubFolder = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("subtitle", data.sub_title);
  formData.append("schedule", data.dates);
  formData.append("scheduleFolderSwitch", data.scheduleFolderSwitch || false);
  formData.append("image", data.img);
  // formData.append("memberAccessLevel", data.memberAccessLevel);
  formData.append("source", data.source);
  formData.append("parentId", data.parentId);
  formData.append("contains", data.contains);
  formData.append("expiryDateSwitch", data.expiryDateSwitch || false);
  formData.append("expiryDate", data.expiryDate || '');

  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  return await axios
    .post(BASEURL + "folder/createFolder", formData, config)
    .then((res) => res);
};
let updateContentCopy = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios
    .patch(BASEURL + "folder/updateContentCopy/" + data.id, data, config)
    .then((res) => res);
};

let copyCompleteFolder = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "folder/copyFolder", data, config)
    .then((res) => res);
};

let updateCourseCopy = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios
    .patch(BASEURL + "courses/copyCourse/" + data.id, data, config)
    .then((res) => res);
};

let completeCourseCopy = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "courses/copyCompleteCourse", data, config)
    .then((res) => res);
};

function folderList(contains) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "folder/getAllFolder/" + userId + "?contains=" + contains,
      configData
    )
    .then((res) => res.data);
}
let deleteFolderById = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "folder/deleteFolderById/" + idd, configData)
    .then((res) => res.data);
};
let updateFolderById = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("subtitle", data.sub_title);
  formData.append("schedule", data.dates);
  formData.append("image", data.img);
  formData.append("scheduleFolderSwitch", data.editFolderShedule);
  formData.append("order", data.order);

  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("source", data.source);
  formData.append("contains", data.contains);
  formData.append("hiddenForMembers", data.hiddenForMembers);
  formData.append("expiryDateSwitch", data.editExpiryDate || false);
  formData.append("expiryDate", data.expiryDate || '');

  return axios
    .put(`${BASEURL}folder/updateFolderById/${data.id}`, formData, config)
    .then((response) => {
      console.log(response.data, "Folder Updated Data");
      return response.data;
    });
};

function getSubFolderList(folder, contains) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "folder/getAllSubFolder/" + folder + "?contains=" + contains,
      configData
    )
    .then((res) => res.data);
}

function subscriptionType() {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + userId, configData)
    .then((res) => res.data);
}

// http://apimemberapp.abcxyz.in/api/subs/subAdminId/61d980f2dd42ee009b065c8c

function getAllsubscription() {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + userId, configData)
    .then((res) => res.data);
}
function getAllMemberThatBuyMembership(memberAccessLevelId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/getAllMemberSubs/" + memberAccessLevelId, configData)
    .then((res) => res.data);
}

let getContent = (id) => {
  const token = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: token },
  };

  return axios
    .get(BASEURL + `content/getContent/${id}`, configData)
    .then((res) => res.data);
};

let getContentById = (id) => {
  const token = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: token },
  };

  return axios
    .get(BASEURL + `content/getContentByFolderId/${id}`, configData)
    .then((res) => res.data);
};

function subscriptionTypeList() {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "substype", configData).then((res) => res.data);
}

let importMember = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  var formData = new FormData();
  formData.append("csv", data.csv);

  try {
    const response = await axios.post(
      BASEURL + "userInvite/uploadUser/" + userId,
      formData,
      config
    );
    console.log("import res", response.data);
    return response.data;
  } catch (error) {
    console.error("import error", error);
    throw error;
  }
};

let addSingleMember = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  console.log("sending data", data);
  var formData = {
    memberFirstName: data.firstName,
    memberLastName: data.lastName,
    memberEmail: data.email,
    memberNumber: data.mobile,
    memberPackage: data.memberPackage,
    memberBirthDate: data.memberBirthDate,
    memberJoinDate: data.memberJoinDate,
    memberLocation: data.memberLocation,
    subsType: data.subsType,
  };

  return await axios
    .post(BASEURL + "userInvite/uploadSingleUser/" + userId, formData, config)
    .then((res) => {
      console.log("import res single member", res);
      return res;
    });
};

let socialWallUsers = async (data) => {
  const token = localStorage.getItem("token");
  const subAdminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + token;
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  try {
    const res = await axios.post(
      BASEURL + "userInvite/socialWallUsers/" + subAdminId,
      data,
      config
    );
    return res;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
let eventAudience = async (data) => {
  const token = localStorage.getItem("token");
  const subAdminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + token;
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  try {
    const res = await axios.post(
      BASEURL + "events/eventAudience/" + subAdminId,
      data,
      config
    );
    return res;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
const sendMailActive = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  console.log("sending data", data.firstName);
  var formData = {
    memberFirstName: data.firstName,
    memberLastName: data.lastName,
    memberEmail: data.email,
    memberNumber: data.mobile,
    memberPackage: data.memberPackage,
    memberBirthDate: data.memberBirthDate,
    memberJoinDate: data.memberJoinDate,
    memberLocation: data.memberLocation,
  };

  try {
    const response = await axios.post(
      BASEURL + "userInvite/SendMailActive/" + userId,
      formData,
      config
    );
    console.log("sssssssss", response);
    return response;
  } catch (error) {
    console.error("Failed to send email:", error);
    throw error;
  }
};

let saveMembership = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const productId = localStorage.getItem("productId");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  console.log(data);
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  // formData.append('name', data.name);
  formData.append("name", new Date().toISOString());
  formData.append("tier", data.tier);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subAdminId);
  formData.append("thumbnailImg", data.thumbnailImg);
  formData.append("signUpBtnText", data.signUpBtnText);
  formData.append("substype", data.substype);
  formData.append("emailId", data.emailId);
  formData.append("emailContent", data.emailContent);
  if (data.substype == "Paid") {
    formData.append("amountOneTime", data.amountOneTime);
    formData.append("amountMonthly", data.amountMonthly);
    formData.append("amountYearly", data.amountYearly);
    //formData.append('subscriptionTypeId', data.subscriptionTypeId);
  } else {
    formData.append("amountOneTime", "0");
    formData.append("amountMonthly", "0");
    formData.append("amountYearly", "0");
  }

  formData.append("successMessageSubject", data.successMessageSubject);
  formData.append("successMessageDescription", data.successMessageDescription);
  formData.append("renewalReminderSubject", data.renewalReminderSubject);
  formData.append(
    "renewalReminderDescription",
    data.renewalReminderDescription
  );

  if (data.substype === "Paid") {
    return axios
      .post(BASEURL + "subs/register", formData, config)
      .then((res) => res);
  } else {
    return axios
      .post(BASEURL + "subs/register", formData, config)
      .then((res) => res);
  }
  //return await axios.post(BASEURL + "subs/register", formData, config).then((res) => res);
};

function templateList() {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "template/getAllTemplates", configData)
    .then((res) => res.data);
}

let createContent = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    subadminId: data1.userId,
    folderId: data1.folderId,
  };

  return await axios
    .post(BASEURL + "content/createContent", data, config)
    .then((res) => res);
};

let updateContent = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    contentData: {
      temp_id: data1.temp_id,
      orderBy: data1.orderBy,
    },
  };

  let content_id = localStorage.getItem("content_id");
  return await axios
    .put(BASEURL + "content/updateContent/" + content_id, data, config)
    .then((res) => res);
};

let postContent = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("title", data.title);
  formData.append("short_description", data.short_description);
  //formData.append('access_level', data.access_level);
  formData.append("source", data.source);
  formData.append("schedule", data.schedule);
  formData.append("content_id", data.content_id);
  formData.append("image", data.image);
  formData.append("subAdminId", data.subAdminId);
  formData.append("previousContent", data.previousContent);
  formData.append(
    "contentCompleteRestriction",
    data.contentCompleteRestriction
  );
  formData.append("contentStatus", data.contentStatus);

  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("access_level", data.memberAccessLevel[key1]);
  }

  return await axios
    .post(BASEURL + "content/postPropertise", formData, config)
    .then((res) => res);
};

let uploadMedia = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("content_id", data.content_id);
  formData.append("order_by", data.order_by);
  formData.append("media", data.media);
  formData.append("title", data.title);
  formData.append("subadminId", data.subadminId);
  formData.append("template_id", data.template_id);
  formData.append("is_image", data.is_image);

  return await axios
    .post(BASEURL + "content/postContent", formData, config)
    .then((res) => res);
};

let uploadText = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("content_id", data.content_id);
  formData.append("order_by", data.order_by);
  formData.append("bodyText", data.bodyText);
  formData.append("subadminId", data.subadminId);
  formData.append("template_id", data.template_id);
  return await axios
    .post(BASEURL + "content/postContent", formData, config)
    .then((res) => res);
};
let updateMedia = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("content_id", data.content_id);
  formData.append("_id", data._id);
  formData.append("order_by", data.order_by);
  formData.append("media", data.media);
  formData.append("title", data.title);
  formData.append("subadminId", data.subadminId);
  formData.append("template_id", data.template_id);
  formData.append("is_image", data.is_image);

  return await axios
    .put(BASEURL + "content/updateContentTemp", formData, config)
    .then((res) => res);
};
let updateText = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("content_id", data.content_id);
  formData.append("_id", data._id);
  formData.append("order_by", data.order_by);
  formData.append("bodyText", data.bodyText);
  formData.append("subadminId", data.subadminId);
  formData.append("template_id", data.template_id);
  return await axios
    .put(BASEURL + "content/updateContentTemp", formData, config)
    .then((res) => res);
};
let updateContentTempData = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .put(BASEURL + "content/updateContentTempData", data, config)
    .then((res) => res);
};

let subscriptionList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + userId, configData)
    .then((res) => res.data);
};

let subscriptionListById = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + userId, configData)
    .then((res) => res.data);
};

let createSocialWall = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId1 = localStorage.getItem("user_id");

  var formData = new FormData();
  formData.append("title", data1.title);
  formData.append("description", data1.description);
  formData.append("subadminId", data1.subadminId);
  formData.append("image", data1.img);

  for (const key of Object.keys(data1.userId)) {
    formData.append("userid", data1.userId[key]);
  }

  for (const key1 of Object.keys(data1.discussion)) {
    formData.append("topicName", data1.discussion[key1]["msg"]);
  }
  for (const key1 of Object.keys(data1.memberAccessLevel)) {
    formData.append("memberAccessLevel", data1.memberAccessLevel[key1]);
  }
  return await axios
    .post(BASEURL + "socialwall/register", formData, config)
    .then((res) => {
      console.log(res, "ooooooo");
    });
};

let editSocialWall = async (id, socialWall) => {
  console.log("Editing wallpost");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId1 = localStorage.getItem("user_id");

  var formData = new FormData();
  formData.append("title", socialWall.title);
  formData.append("description", socialWall.description);
  formData.append("subadminId", socialWall.subadminId);
  formData.append("image", socialWall.img);
  formData.append("existingImageUrl", socialWall.existingImageUrl);
  formData.append("hiddenForMembers", socialWall.hiddenForMembers);

  for (const key of Object.keys(socialWall.userId)) {
    formData.append("userid", socialWall.userId[key]);
  }

  for (const key1 of Object.keys(socialWall.discussion)) {
    formData.append("topicName", socialWall.discussion[key1]["msg"]);
  }
  for (const key1 of Object.keys(socialWall.memberAccessLevel)) {
    formData.append("memberAccessLevel", socialWall.memberAccessLevel[key1]);
  }
  return await axios
    .put(BASEURL + "socialwall/" + id, formData, config)
    .then((res) => {
      console.log(res, "hhhh");
    });
};

let SocialList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/totalsocialwall/" + userId, configData)
    .then((res) => res.data);
};

let getAllUsers = async () => {
  const userId = localStorage.getItem("user_id");
  const userId1 = "613884aa286e87f188bd0f2e";
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/allusers/" + userId, configData)
    .then((res) => res.data);
};

let createSocialPost = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("socialWallId", data.socialWallId);
  formData.append("description", data.description);
  formData.append("subadminId", userId || "");

  for (const key of Object.keys(data.imagesId)) {
    formData.append("imagesId", data.imagesId[key]);
  }

  return await axios
    .post(BASEURL + "post/register", formData, config)
    .then((res) => res);
};

let moduleList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "modules", configData).then((res) => res.data);
};

let getPostList = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "post/totalpostlist/" + idd, configData)
    .then((res) => res.data);
};

let uploadAudio = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("audio", data.audio);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subadminid);
  formData.append("folderId", data.folderId);
  return await axios
    .post(BASEURL + "audio/upload", formData, config)
    .then((res) => res);
};

let uploadVideo = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();

  formData.append("video", data.video);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subadminid);
  formData.append("folderId", data.folderId);
  return await axios
    .post(BASEURL + "video/upload", formData, config)
    .then((res) => res);
};

let getAudioByFolderId = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "audio/getAudioByFolderId/" + idd + "?contains=audios",
      configData
    )
    .then((res) => res.data);
};

let getVideoByFolderId = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "video/getVideoByFolderId/" + idd + "?contains=videos",
      configData
    )
    .then((res) => res.data);
};

let saveVideoInfo = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();

  formData.append("coverImage", data.coverImage);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subAdminId);
  formData.append("folderId", data.folderId);
  formData.append("schedule", data.schedule);
  return await axios
    .put(BASEURL + "video/updateVideoById/" + data.videoId, formData, config)
    .then((res) => res);
};

let saveAudioInfo = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();

  formData.append("coverImage", data.coverImage);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subAdminId);
  formData.append("folderId", data.folderId);
  formData.append("schedule", data.schedule);
  formData.append("memberAccessLevel", data.memberAccessLevel);
  return await axios
    .put(BASEURL + "audio/updateAudioById/" + data.audioId, formData, config)
    .then((res) => res);
};

let getRecentVideo = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/recentVideo/" + idd, configData)
    .then((res) => res.data);
};

let getscheduledVideo = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/scheduledVideo/" + idd, configData)
    .then((res) => res.data);
};

let getTopVideo = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/popular/" + idd, configData)
    .then((res) => res.data);
};

let deleteVideo = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "video/" + idd, configData)
    .then((res) => res.data);
};

let getRecentAudio = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/recentAudio/" + idd, configData)
    .then((res) => res.data);
};

let getscheduledAudio = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/scheduledAudio/" + idd, configData)
    .then((res) => res.data);
};

let getTopAudio = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/popular/" + idd, configData)
    .then((res) => res.data);
};

let deleteAudio = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "audio/" + idd, configData)
    .then((res) => res.data);
};

let likevideo = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    videoId: data1.videoId,
    subAdminId: data1.subAdminId,
    likedByUserId: {
      userId: data1.userId,
    },
  };
  return await axios
    .post(BASEURL + "video/like/register", data, config)
    .then((res) => res);
};

let savecomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    videoId: data1.videoId,
    subAdminId: data1.subAdminId,
    commentByUserId: {
      subadminId: data1.subAdminId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "video/subadmin/comment/register", data, config)
    .then((res) => res);
};

let getcomments = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/totalcomment/" + idd, configData)
    .then((res) => res.data);
};

let saveAudiocomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    audioId: data1.audioId,
    subAdminId: data1.subAdminId,
    commentByUserId: {
      subadminId: data1.subAdminId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "audio/subadmin/comment/register", data, config)
    .then((res) => res);
};

let likeaudio = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    audioId: data1.audioId,
    subAdminId: data1.subAdminId,
    likedByUserId: {
      subAdminId: data1.subAdminId,
    },
  };
  return await axios
    .post(BASEURL + "audio/like/register", data, config)
    .then((res) => res);
};

let audioCommentlist = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/totalcomment/" + idd, configData)
    .then((res) => res.data);
};

let getInviteeUsers = async () => {
  const idd = localStorage.getItem("user_id");
  if (!idd) new Error("User id not found");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  //return axios.get(BASEURL + 'socialwall/allusers/'+idd, configData).then(res => res.data);
  return axios
    .get(BASEURL + "subadmin/inviteuserlist/" + idd, configData)
    .then((res) => res.data);
};

let LatestSocialList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/recent/" + userId, configData)
    .then((res) => res.data);
};

let getSocialWallById = async (id) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/" + id, configData)
    .then((res) => res.data);
};

let getPostByWallId = async (id) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "post/totalpostlist/" + id, configData)
    .then((res) => res.data);
};

let deletePost = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "post/" + idd, configData)
    .then((res) => res.data);
};

let deleteWall = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "socialwall/" + idd, configData)
    .then((res) => res.data);
};

let savePostcommentData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    commentByUserId: {
      userId: data1.userId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "post/comment/register", data, config)
    .then((res) => res);
};

let saveAdminPostcommentData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    commentByUserId: {
      subadminId: data1.userId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "post/subadmin/comment/register", data, config)
    .then((res) => res);
};

let savePostLikeData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    likedByUserId: { userId: data1.userId },
  };

  return await axios
    .post(BASEURL + "post/like/register", data, config)
    .then((res) => res);
};

let saveAdminPostLikeData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    likedByUserId: { subadminId: data1.userId },
  };

  return await axios
    .post(BASEURL + "post/subadmin/like/register", data, config)
    .then((res) => res);
};

let searchWall = async (searchText) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios
    .get(BASEURL + "socialwall/byTitle/" + userId + "/" + searchText, config)
    .then((res) => res);
};

let PopularSocialList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/popularpost/" + userId, configData)
    .then((res) => res.data);
};

let updateSubAdmin = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();

  formData.append("phoneNumber", data.phone);
  formData.append("countryCode", data.countryCode);
  // formData.append("password", data.password);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("timezone", data.timezone);
  if (data.image) {
    formData.append("image", data.image);
  }
  return await axios
    .put(BASEURL + "subadmin/" + data.user_id, formData, config)
    .then((res) => res);
};
let updateSubAdminSubscriptionStatus = async (subscriptionStatus) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();

  formData.append("subscriptionStatus", subscriptionStatus);
  return await axios
    .patch(BASEURL + "subadmin/subsStatus/" + userId, formData, config)
    .then((res) => {
      console.log(res, "subscription status checked");
      return res;
    });
};

let updateSubAdminDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  if (data.img) {
    formData.append("image", data.img);
  }
  formData.append("brandColor", data.bgcolor);
  formData.append("membershipName", data.membershipName);

  return await axios
    .put(BASEURL + "subadmin/" + data.user_id, formData, config)
    .then((res) => res);
};

let updateCompanyDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: bearer },
  };

  const formData = {
    name: data.companyName,
    city: data.city,
    address: data.address,
    country: data.country,
    email: data.companyemail,
    websiteURL: data.website,
    contactNumber: data.contactno,
    countryCode: data.countrycode,
    logo: data.img,
    imageUrl: data.imgUrl,
  };
  return await axios
    .put(BASEURL + "subadmin/company/" + data.user_id, formData, config)
    .then((res) => res);
};

let forgotPassword = async (email) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    email: email,
  };
  return await axios
    .post(BASEURL + "subadmin/forgetpwd", data, config)
    .then((res) => res);
};

function activeSaSubscription(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId1 = localStorage.getItem("user_id");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getallsubs/" + userId, configData)
    .then((res) => res.data);
}
function getUserByEmailAtPartnerSide(email) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId1 = localStorage.getItem("user_id");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getUserByEmail/" + email, configData)
    .then((res) => res.data);
}

let updateWelcomeNote = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    welcomeNote: data1.welcomeNote,
  };
  return await axios
    .put(
      BASEURL + "subadmin/welcomeNote/register/" + data1.userId,
      data,
      config
    )
    .then((res) => res);
};

let updateNewsData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    newsData: data1.newsData,
  };
  return await axios
    .put(
      BASEURL + "subadmin/newsData/register/" + data1.userId,
      data,
      config
    )
    .then((res) => res);
};

let updateTerms = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    termsCondition: data1.terms,
    privacyPolicy: data1.privacy,
    emailDisclaimer: data1.disclaimer,
  };
  return await axios
    .put(
      BASEURL + "subadmin/termsCondition/register/" + data1.userId,
      data,
      config
    )
    .then((res) => res);
};

let updateNotificationStatus = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    status: data1.status,
    identityValue: data1.identityValue,
  };
  return await axios
    .put(BASEURL + "setting/notification/update/" + data1.id, data, config)
    .then((res) => res);
};

let updateEngageNotificationStatus = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    _id: data1.id,
    status: data1.status,
  };
  return await axios
    .put(
      BASEURL + "subadmin/notification/memberEngagment/" + data1.userId,
      data,
      config
    )
    .then((res) => res);
};

let updateUnsubscribePolicy = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    unsubscribeDescription: [{ text: data1.text }],
  };
  return await axios
    .put(BASEURL + "subadmin/subscribe/register/" + data1.userId, data, config)
    .then((res) => res);
};

let deleteUnsubscribedata = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    _id: idd,
  };
  return await axios
    .post(BASEURL + "subadmin/subscribe/delete/" + userId, data, config)
    .then((res) => res);
};

let updateRecords = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    _id: data1.currentId,
    text: data1.text,
  };
  return await axios
    .put(BASEURL + "subadmin/subscribe/update/" + data1.userId, data, config)
    .then((res) => res);
};

function getAllNotification(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId1 = localStorage.getItem("user_id");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "setting/notification", configData)
    .then((res) => res.data);
}

let deleteMember = async (invitesUserId) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {};
  //  return axios.delete(BASEURL + 'subadmin/deleteInviteUser/' + userId + '/' + idd, configData).then(res => res.data)
  // return axios.post(BASEURL + 'subadmin/user/'+useridd +'/Delete', configData).then(res => res.data)
  return axios
    .delete(
      `${BASEURL}subadmin/deleteInviteUser/${userId}/${invitesUserId}`,
      config
    )
    .then((res) => res.data);
};
let reinviteSingleMem = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .post(BASEURL + "userInvite/reinviteSingleUser/" + userId, data, config)
    .then((res) => res.data);
};

let getMemberById = async (id) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "userInvite/getbyid/" + id, configData)
    .then((res) => res.data);
};
let getStudentsById = async (id) => {
  // userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "userInvite/getEnrolledStudentById/" + id, configData)
    .then((res) => res.data)
    .catch((err) => {
      console.error(`Error fetching student with ID ${id}:`, err);
      return { status: err.response ? err.response.status : 500 };
    });
};

let updateMember = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  var formData = new FormData();
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("email", data.email);
  formData.append("mobile", data.mobile);
  formData.append("birthdate", data.memberBirthDate);
  formData.append("joined_date", data.memberJoinDate);
  formData.append("subsPlan", data.memberPackage);
  formData.append("subsType", data.subsType);
  formData.append("location", data.memberLocation);
  formData.append("_id", data.memberId);

  return await axios
    .put(BASEURL + "subadmin/editInviteUser/" + userId, formData, config)
    .then((res) => res);
};

let updateAdminPassword = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    subadminId: data1.userId,
    password: data1.currentPassword,
    newPassword: data1.newPassword,
    confirmPassword: data1.conPassword,
  };
  return await axios
    .put(BASEURL + "subadmin/changepwd/" + data1.userId, data, config)
    .then((res) => res);
};

let getContentByFolderId = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/getContentByFolderId/" + idd, configData)
    .then((res) => res.data);
};

let getRecentContent = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/recentContent/" + idd, configData)
    .then((res) => res.data);
};

let getscheduledContent = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/scheduledContent/" + idd, configData)
    .then((res) => res.data);
};

let getTopContent = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/popular/" + idd, configData)
    .then((res) => res.data);
};

let createEvents = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();

  // console.log(data);

  formData.append("subadminId", userId || "");
  formData.append("name", data.event_name);
  formData.append("description", data.description);
  formData.append("liveStreamUrl", data.url);
  formData.append("eventDate", data.startDate);
  formData.append("eventAmount", data.eventAmount);
  formData.append("eventAcceptIndPayment", data.eventAcceptIndPayment);
  formData.append("eventEndDate", data.endDate);
  // formData.append("start", data.startTime);
  // formData.append("finish", data.endTime);
  formData.append("image", data.img);
  formData.append("eventShowOnSalesPage", data.eventShowOnSalesPage);
  if (data.eventFiles) {
    for (const key1 of Object.keys(data.eventFiles)) {
      formData.append("eventFiles", data.eventFiles[key1]);
    }
  }
  formData.append("physicalEvent", data.physical_event);
  formData.append("acceptingParticipant", data.acceptingparticipants);
  formData.append("saveEventReport", data.saveEventReport);
  formData.append("previousEvent", data.previousEvent);

  //formData.append('memberAccessLevel', data.memberAccessLevel);
  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  // for (const key of Object.keys(data.userId)) {
  //   formData.append('userid', data.userId[key])
  // }
  // console.log('userid');

  return await axios
    .post(BASEURL + "events/register", formData, config)
    .then((res) => res);
};
// http://apimemberapp.abcxyz.in/api/events/register

// For Updating Form

let updatingEvents = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();

  // console.log(data);

  formData.append("subadminId", userId || "");
  formData.append("name", data.event_name);
  formData.append("description", data.description);
  formData.append("liveStreamUrl", data.url);
  formData.append("eventDate", data.startDate);
  formData.append("eventEndDate", data.endDate);
  formData.append("start", data.startTime);
  formData.append("finish", data.endTime);
  formData.append("image", data.img);
  formData.append("physicalEvent", data.physical_event);
  if (data.eventFiles) {
    for (const key1 of Object.keys(data.eventFiles)) {
      formData.append("eventFiles", data.eventFiles[key1]);
    }
  }
  formData.append("acceptingParticipant", data.acceptingparticipants);
  formData.append("saveEventReport", data.repeat);
  formData.append("memberAccessLevel", data.memberAccessLevel);

  // for (const key of Object.keys(data.userId)) {
  //   formData.append('userid', data.userId[key])
  // }
  // console.log('userid');

  return await axios
    .put(BASEURL + "events/register", formData, config)
    .then((res) => res);
};

let getAllEvents = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/total/" + userId, configData)
    .then((res) => res.data);
};
let getActiveUsers = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "shared/stats/" + userId, configData)
    .then((res) => res.data);
};

let eventDetails = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/" + idd, configData)
    .then((res) => res.data);
};

let saveCommentData = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "events/comment/register", data, config)
    .then((res) => res);
};

let saveEventCommentData = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "events/subadmin/comment/register", data, config)
    .then((res) => res);
};

let getAllUpcomingEvents = async () => {
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    return {
      isSuccess: false,
      message: "User id not found",
    };
  }
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/adminUpcomingEvents/" + userId, configData)
    .then((res) => res.data);
};
let getPreviousYearEvent = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/getPreviousYearEvent/" + userId, configData)
    .then((res) => res.data);
};
let copyEventNumberData = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .patch(BASEURL + "events/copyEventNumber/" + data.id, data, configData)
    .then((res) => res.data);
};

let deleteEvent = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .delete(BASEURL + `events/delete/${id}`, config)
    .then((res) => res);
};

let deleteContent = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "content/delete/" + idd, configData)
    .then((res) => res.data);
};

let registerCourse = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);

  if (typeof data.courseAmount !== "undefined") {
    formData.append("amount", data.courseAmount);
  }

  if (typeof data.acceptIndPayment !== "undefined") {
    formData.append("acceptIndPayment", data.acceptIndPayment);
  }
  formData.append("shortDescription", data.shortDescription);
  formData.append("description", data.description);
  formData.append("courseImage", data.courseImage);
  formData.append("schedule", data.schedule);
  formData.append("paymentLink", data.paymentLink);
  formData.append(
    "showOnSalesPage",
    data?.showOnSalesPage ? data?.showOnSalesPage : false
  );
  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("contains", data.contains);
  return await axios
    .post(BASEURL + "courses/register", formData, config)
    .then((res) => res);
};

let updateCourse = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("amount", data.courseAmount);
  formData.append("acceptIndPayment", data.acceptIndPayment);
  formData.append("shortDescription", data.shortDescription);
  formData.append("description", data.description);
  if (data.courseImage) {
    formData.append("courseImage", data.courseImage);
  }
  formData.append("schedule", data.schedule);
  formData.append("paymentLink", data.paymentLink);
  formData.append("enrollment", data?.enrollment);
  formData.append("hiddenForMembers", data?.hiddenForMembers);
  formData.append("showOnSalesPage", data?.showOnSalesPage);
  // if (data.memberAccessLevel) {
  //   formData.append('memberAccessLevel', data.memberAccessLevel);
  // }
  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("contains", data.contains);

  var currentCourse = data.currentCourse;
  console.log(currentCourse, "course_api");
  console.log(data, "courseapi_data");

  return await axios
    .put(BASEURL + "courses/" + currentCourse, formData, config)
    .then((res) => res);
};
let updateCourseForSalesPage = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  
  return await axios
    .patch(BASEURL + "courses/updateCourseForSalesPage", data, config)
    .then((res) => console.log(res,"updated lesson salespage status"))
    .catch((err) => {
      console.error("Error updating courses for sales page:", err);
      throw err;
    });
};
let updateEventForSalesPage = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  
  let userId=localStorage.getItem("user_id");

  return await axios
    .patch(BASEURL + "events/updateEventOnSalePage/"+userId, data, config)
    .then((res) => console.log(res,"updated event salespage status"))
    .catch((err) => {
      console.error("Error updating event for sales page:", err);
      throw err;
    });
};
// challenge update Api

let updatechCourse = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("shortDescription", data.shortDescription);
  formData.append("description", data.description);
  if (data.courseImage) {
    formData.append("challengeImage", data.courseImage);
  }
  formData.append("schedule", data.schedule);
  formData.append("amount", data.amount);
  formData.append("paymentLink", data.paymentLink);
  formData.append("enrollment", data.challengeEnrollment);
  formData.append("hiddenForMembers", data.challengeHiddenForMembers);
  formData.append("showOnSalesPage", data?.showOnSalesPage);
  // if (data.memberAccessLevel) {
  //   formData.append('memberAccessLevel', data.memberAccessLevel);
  // }

  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("contains", data.contains);

  var currentChallenge = data.currentChallenge;

  return await axios
    .put(BASEURL + "challenge/" + currentChallenge, formData, config)
    .then((res) => res);
};

let uploadLesson = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  // formData.append('subadminid', data.subadminid);
  formData.append("lessonsFile", data.lessonsFile);
  // return await axios.post(BASEURL + "chLesson/uploadEvent/", formData, config).then(res => res);

  return await axios
    .post(BASEURL + "lesson/uploadLesson/", formData, config)
    .then((res) => res);
};

let addLessonDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const lessonId = data.lessonId;

  var formData = new FormData();
  formData.append("subadminId", data.subadminid);
  formData.append("courseId", data.courseId);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("schedule", data.schedule);
  formData.append("memberAccessLevel", data.memberAccessLevel);
  //formData.append('newResource', data.newResource);
  formData.append("parentId", lessonId);
  for (const key1 of Object.keys(data.newResource)) {
    //formData.append('topicName', data.discussion[key1]['msg'])
    formData.append("newResource", data.newResource[key1]);
    //console.log(data.newResource);
  }

  // return await axios.put(BASEURL + "chLesson/" + lessonId, formData, config).then(res => res);

  return await axios
    .put(BASEURL + "lesson/" + lessonId, formData, config)
    .then((res) => res);
};

// 0: (2) ['newResource', {…}]
// 1: (2) ['subadminId', '6273d7da102ecd104d78f9ed']
// 2: (2) ['challengeId', '6294531ffcf70c788266814a']
// 3: (2) ['title', 'Schedule lesson new ']
// 4: (2) ['parentId', '62946942fcf70c788266cc7f']
// 5: (2) ['description', 'Wdjowd']
// 6: (2) ['schedule', '2022-5-31']
// 7: (2) ['memberAccessLevel', '627b4c21f4002792bdceb919']
// length: 8

let addChallengeLessonDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("subadminId", data.subadminid);
  formData.append("challengeId", data.courseId);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("newEventFile", data.newEventFile);
  if (data.schedule) {
    formData.append("schedule", data.schedule);
  }

  if (Array.isArray(data?.memberAccessLevel)) {
    for (const key1 of Object.keys(data.memberAccessLevel)) {
      formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
    }
  } else {
    if (typeof data?.memberAccessLevel === "string") {
      formData.append("memberAccessLevel", data.memberAccessLevel);
    }
  }

  //formData.append('newResource', data.newResource);
  // if (data.newEventFile) {
  //   for (const key1 of Object.keys(data.newEventFile)) {
  //     formData.append("newEventFile", data.newEventFile[key1]);
  //   }
  // }

  if (data.newResource) {
    for (const key1 of Object.keys(data.newResource)) {
      formData.append("newResource", data.newResource[key1]);
    }
  }

  formData.append("parentId", data.lessonId);
  formData.append("videoUrl", "aaa");
  formData.append("previewLesson", data.previewLesson ? data.previewLesson : false);

  const lessonId = data.lessonId;
  return await axios
    .put(BASEURL + "chLesson/" + lessonId, formData, config)
    .then((res) => res);
};

// let addLessonDetails = async (data) => {
//   const bearer = "Bearer " + localStorage.getItem('token');
//   const config = {
//       headers: { 'content-type': 'application/json', 'Authorization': bearer }
//   }
//   var formData = new FormData();
//   formData.append('subadminid', data.subadminid);
//   formData.append('courseId', data.courseId);
//   formData.append('title', data.title);
//   formData.append('description', data.description);
//   formData.append('parentId', 0);
//   formData.append('schedule', data.schedule);
//   formData.append('memberAccessLevel', data.memberAccessLevel);
//   formData.append('newResource', data.newResource);
//   const lessonId = data.lessonId;
//   return await axios.post(BASEURL + "lesson/register", formData, config).then(res => res);
// }

function totalCourses() {
  //const userId = localStorage.getItem('subadminId');
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/totalcourses/" + userId, configData)
    .then((res) => res.data);
}

let getRecentCourses = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/recentcourses/" + userId, configData)
    .then((res) => res.data);
};
let getAcceptIndividualPayCourses = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/acceptIndividualPayment/" + userId, configData)
    .then((res) => res.data);
};
let getAcceptIndividualPayEvents = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/acceptIndividualPayment/" + userId, configData)
    .then((res) => res.data);
};

let getScheduledCourses = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/scheduled_courses/" + userId, configData)
    .then((res) => res.data);
};

function getLessonsByCourseId(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/" + idd, configData)
    .then((res) => res.data);
}

function getTotalUserByCourseId(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/totalUser/" + idd, configData)
    .then((res) => res.data);
}

function totalLessonCount(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/totalLessonCount/" + idd, configData)
    .then((res) => res.data);
}

function courseDetailsApi(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/" + idd, configData)
    .then((res) => res.data);
}

function livelesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/live_lessons/" + idd, configData)
    .then((res) => res.data);
}

function getLiveLessons(userId, courseId, moduleId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let apiPath = "lesson/live_lessons/" + userId;
  if (courseId) {
    apiPath += "/" + courseId;
  }
  if (moduleId) {
    apiPath += "/" + moduleId;
  }
  return axios.post(BASEURL + apiPath, configData).then((res) => res.data);
}

function schedulelesson(idd, courseId, moduleId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let apiPath = "lesson/scheduled_lessons/" + idd;
  if (courseId) {
    apiPath += "/" + courseId;
  }
  if (moduleId) {
    apiPath += "/" + moduleId;
  }
  return axios.post(BASEURL + apiPath, configData).then((res) => res.data);
}

function draftlesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/draftlessons/" + idd, configData)
    .then((res) => res.data);
}
let deleteChallenge = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "challenge/" + idd, configData)
    .then((res) => res.data);
};
let deletecourse = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "courses/" + idd, configData)
    .then((res) => res.data);
};

let deleteLesson = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "lesson/" + idd, configData)
    .then((res) => res.data);
};

let deleteLessonch = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "chlesson/" + idd, configData)
    .then((res) => res.data);
};
let deleteChLessonComment = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}chlesson/deletechallengelessoncomment/${lessonId}/${commentId}`,
      configData
    );
    return response.data; 
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error; 
  }
};
let deleteCourseLessonComment = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}lesson/deletecourselessoncomment/${lessonId}/${commentId}`,
      configData
    );
    return response.data; 
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error; 
  }
};
let deleteEventComment = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}events/deleteeventcomment/${lessonId}/${commentId}`,
      configData
    );
    return response.data; 
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error; 
  }
};
let deletePostComment = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}post/deletepostcomment/${lessonId}/${commentId}`,
      configData
    );
    return response.data; 
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error; 
  }
};

let updateCLessonCopyNum = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .patch(
      BASEURL + "chLesson/updateChLessonCopyNum/" + data.id,
      data,
      configData
    )
    .then((res) => res.data);
};

function getLessonById(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/" + idd, configData)
    .then((res) => res.data);
}
function updateCourLessonNum(data) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .patch(
      BASEURL + "lesson/updateCourseLessonNum/" + data.id,
      data,
      configData
    )
    .then((res) => res.data);
}
function saveNewLessonOrder(data){
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const payload = {
    updatedLessonOrder: data,
  };

  return axios
    .patch(
      BASEURL + "lesson/saveNewLessonOrder",
      payload,
      configData
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error saving lesson order:', error);
      throw error;
    });
}
function getLessonByIdch(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/" + idd, configData)
    .then((res) => res.data);
}

// challenge preview api

function getchLessonById(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/" + idd, configData)
    .then((res) => res.data);
}

function totalChallenges() {
  //const userId = localStorage.getItem('subadminId');
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/totalChallenges/" + userId, configData)
    .then((res) => res.data);
}

let getRecentChallenges = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/recentChallenges/" + userId, configData)
    .then((res) => res.data);
};

let getScheduledChallenges = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/scheduleChallenges/" + userId, configData)
    .then((res) => res.data);
};

let registerChallenges = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  formData.append("title", data.title);
  formData.append("shortDescription", data.shortDescription);
  formData.append("description", data.description);
  formData.append("challengeImage", data.courseImage);
  formData.append("schedule", data.schedule);
  formData.append("amount", "0");
  formData.append("paymentLink", data.paymentLink);
  //formData.append('memberAccessLevel', data.memberAccessLevel);

  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  formData.append("contains", data.contains);

  // title:challenge For Dec-14
  // shortDescription: MongoDB series
  // description:All series available
  // folderId:61b8849b77cbbc1ec7ec747d
  // amount:500
  // paymentLink:http://paymentcourse/sfhsfkjsdf1
  // schedule:2021-12-28
  // memberAccessLevel:61af47b5226b17259702070c
  // subadminId:61af2296ecdde127e10bd74d

  return await axios
    .post(BASEURL + "challenge/register", formData, config)
    .then((res) => res);
};

let getTopChallenges = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/recentChallenges/" + userId, configData)
    .then((res) => res.data);
};

function challengesDetailsApi(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/" + idd, configData)
    .then((res) => res.data);
}

let coursecommentlist = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/comment/total/" + idd, configData)
    .then((res) => res.data);
};

let saveCoursecomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    lessonId: data1.lessonId,
    courseId: data1.courseId,
    commentByUserId: {
      subadminId: data1.subadminId,
      comment: data1.comment,
    },
  };
  return await axios
    .post(BASEURL + "lesson/comment/subadmin/register", data, config)
    .then((res) => res);
};

let getTopCourses = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId || "");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/topcourses/" + userId, configData)
    .then((res) => res.data);
};

let uploadChallengeLesson = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("subadminid", data.subadminid);
  formData.append("eventFile", data.lessonsFile);
  return await axios
    .post(BASEURL + "chLesson/uploadEvent/", formData, config)
    .then((res) => res);
};

function challengelivelesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/live_lessons/" + idd, configData)
    .then((res) => res.data);
}

function challengeschedulelesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/scheduled_lessons/" + idd, configData)
    .then((res) => res.data);
}

function challengedraftlesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/draftlessons/" + idd, configData)
    .then((res) => res.data);
}

function getChannelLessonById(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/" + idd, configData)
    .then((res) => res.data);
}

let saveChannelcomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    lessonId: data1.lessonId,
    challengeId: data1.courseId,

    commentByUserId: {
      subadminId: data1.subadminId,
      comment: data1.comment,
    },
  };
  console.log(data, "challengecommentdata");
  return await axios
    .post(BASEURL + "chLesson/comment/subadmin/register", data, config)
    .then((res) => res);
};

let channelcommentlist = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/comment/total/" + idd, configData)
    .then((res) => res.data);
};

function getPostDetails(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "post/" + idd, configData).then((res) => res.data);
}

function getAllCommentsofEvents(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/totalcomment/" + idd, configData)
    .then((res) => res.data);
}

let subscribefreeupgrade = async (idd, pabb_id) => {
  const userId = localStorage.getItem("user_id");
  let pabblyUserSubsId = localStorage.getItem("subsId");
  localStorage.setItem(
    "subsOldId",
    pabblyUserSubsId != null ? pabblyUserSubsId : ""
  );
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    subscriptionId: {
      subsId: idd,
    },
    pabblySubscriptionId: pabb_id,
  };

  return await axios
    .post(BASEURL + "subadmin/upgradeFreeSubs/" + userId, data, config)
    .then((res) => {
      //console.log(res, "free subscription response");
      return res;
    });
};

const dragDropCotent = async (contentId, data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios.put(
    BASEURL + "content/updateContentTempOrder/" + contentId,
    data,
    config
  );
};

let deleteTemplate = async (content_id, templateId) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .put(
      BASEURL +
        "content/deleteContentTemplate/" +
        content_id +
        "/" +
        templateId,
      configData
    )
    .then((res) => res.data);
};

let postContentUpdate = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("title", data.title);
  formData.append("short_description", data.short_description);
  formData.append("source", data.source);
  formData.append("schedule", data.schedule);
  formData.append("subAdminId", data.subAdminId);
  formData.append("content_id", data.content_id);
  formData.append("previousContent", data.previousContent);
  formData.append("order", data.order);
  if (data.image) {
    formData.append("image", data.image);
  }
  for (const key1 of Object.keys(data.access_level)) {
    formData.append("access_level", data.access_level[key1]);
  }
  return await axios.put(
    BASEURL + "content/updatePostPropertise",
    formData,
    config
  );
};

// member user toggle

let updateUserStatus = async (id, status) => {
  if (!status) return;
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  await axios
    .post(
      BASEURL + "subadmin/user/" + id + "/" + status + "/" + subadminId,
      {},
      config
    )
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
};

function courseLessons(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/totalLessonCount/" + idd, configData)
    .then((res) => res.data);
}

let updateLessonDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();

  formData.append("subadminId", userId || "");
  //formData.append('courseId', data.lesson_id);
  formData.append("title", data.title);
  formData.append("goal", data.goal);
  formData.append("description", data.description);
  formData.append("moduleId", data.moduleId);
  formData.append("lessonOrder", data.lessonOrder);
  formData.append(
    "lessonCompletionRestriction",
    data.lessonCompletionRestriction
  );
  // formData.append("previousLesson", data.previousLesson);
  formData.append("lessonStatus", data.lessonStatus);
  formData.append("hideForMember", data.hideForMember);
  formData.append("lessonPreview", data.lessonPreview);
  if (data.schedule) {
    formData.append("schedule", data.schedule);
  }

  if (Array.isArray(data.memberAccessLevel)) {
    for (const key of Object.keys(data.memberAccessLevel)) {
      formData.append("memberAccessLevel", data.memberAccessLevel[key]);
    }
  } else {
    formData.append("memberAccessLevel", data.memberAccessLevel);
  }
  if (data?.selectedFile) {
    formData.append("newLesson", data.selectedFile);
  }
  if (data?.lessonFiles) {
    for (const key of Object.keys(data.lessonFiles)) {
      formData.append("newLesson", data.lessonFiles[key]);
    }
  }
  if (data?.newResource) {
    for (const key1 of Object.keys(data.newResource)) {
      formData.append("newResource", data.newResource[key1]);
    }
  }

  if (data?.videoUrl) {
    formData.append("videoUrl", data.videoUrl);
  }

  var lesson_id = data.lesson_id;

  return await axios
    .put(BASEURL + "lesson/" + lesson_id, formData, config)
    .then((res) => res);
};

//  For edit-Update challenge Api

let updatechLessonDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();

  formData.append("subadminId", userId || "");
  formData.append("challengeId", data.challengeId || "");
  //formData.append('courseId', data.lesson_id);
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("hideForMember", data.hideForMember || false);
  if (data.schedule) {
    formData.append("schedule", data.schedule);
  }
  formData.append("previewLesson", data.previewLesson ? data.previewLesson : false);

  //formData.append('memberAccessLevel', data.memberAccessLevel);
  for (const key1 of Object.keys(data.memberAccessLevel)) {
    formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
  }

  if (data?.eventFile) {
    for (const key of Object.keys(data.eventFile)) {
      formData.append("newEventFile", data.eventFile[key]);
    }
  }

  if (data?.newResource) {
    for (const key1 of Object.keys(data.newResource)) {
      formData.append("newResource", data.newResource[key1]);
    }
  }
  if (data?.newResourceChLesson) {
    for (const key1 of Object.keys(data.newResourceChLesson)) {
      formData.append("newResourceChLesson", data.newResourceChLesson[key1]);
    }
  }

  if (data?.videoUrl) {
    formData.append("videoUrl", data.videoUrl);
  }

  var lesson_id = data.lesson_id;

  return await axios
    .put(BASEURL + "chlesson/" + lesson_id, formData, config)
    .then((res) => res);
};

let deleteResources = async (lesson_id, resourceId) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(
      BASEURL + "chlesson/delResource/" + lesson_id + "/" + resourceId,
      configData
    )
    .then((res) => {
      console.log(res, "response del");
      return res;
    });
};
let deleteLessonResources = async (lesson_id, resourceId) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(
      BASEURL + "lesson/delResource/" + lesson_id + "/" + resourceId,
      configData
    )
    .then((res) => {
      console.log(res, "response del");
      return res;
    });
};

function CourseLatestlesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  // return axios.get(BASEURL + 'lesson/latest_lessons/one_course' + idd, configData).then(res => res.data)
  return axios
    .get(BASEURL + "lesson/latest_lessons/one_course/" + idd, configData)
    .then((res) => res.data);
}

function CourseSchedulelesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  // return axios.get(BASEURL + 'lesson/scheduled_lessonsOfOneCourse/' + idd, configData).then(res => res.data)
  return axios
    .get(BASEURL + "lesson/scheduled_lessons/one_course/" + idd, configData)
    .then((res) => res.data);
}

let updateAdminForgotPassword = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    newPassword: data1.newPassword,
    confirmPassword: data1.conPassword,
  };
  return await axios
    .put(BASEURL + "subadmin/setpwd/" + data1.userId, data, config)
    .then((res) => res);
};

let updateEvents = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();

  formData.append("name", data.event_name);
  formData.append("description", data.description);
  formData.append("liveStreamUrl", data.url);
  formData.append("eventDate", data.startDate);
  formData.append("eventEndDate", data.endDate);
  formData.append("eventAcceptIndPayment", data.eventAcceptIndPayment);
  formData.append("eventAmount", data.eventAmount);
  // formData.append("start", data.startTime);
  // formData.append("finish", data.endTime);
  if (data.img) {
    formData.append("image", data.img);
  }
  formData.append("physicalEvent", data.physical_event);
  formData.append("acceptingParticipant", data.acceptingparticipants);
  formData.append("subadminId", data.subadminId);
  formData.append("saveEventReport", data.saveEventReport);
  formData.append("hiddenForMembers", data.hiddenForMembers);
  formData.append("eventShowOnSalesPage", data.eventShowOnSalesPage);

  //formData.append('memberAccessLevel', data.memberAccessLevel);
  if (data?.memberAccessLevel.length > 0) {
    for (const key1 of Object.keys(data.memberAccessLevel)) {
      formData.append("memberAccessLevel", data.memberAccessLevel[key1]);
    }
  } else {
    formData.append("memberAccessLevel", data.memberAccessLevel);
  }
  if (data.eventFiles) {
    for (const key1 of Object.keys(data.eventFiles)) {
      formData.append("eventFiles", data.eventFiles[key1]);
    }
  }
  return await axios
    .put(BASEURL + "events/update/" + data.eventId, formData, config)
    .then((res) => res);
};

// api/chLesson/latest_lessons/one_challenge/624e94cbf7d1410e816e3716

// api/chLesson/schedule_lessons/one_challenge/624e94cbf7d1410e816e3716

function recentChallenges(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chLesson/latest_lessons/one_challenge/" + idd, configData)
    .then((res) => res.data);
}

function scheduledChallenges(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chLesson/schedule_lessons/one_challenge/" + idd, configData)
    .then((res) => res.data);
}

function challengeDetailsApi(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/" + idd, configData)
    .then((res) => res.data);
}
function updateChallengeCopy(data) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .patch(
      BASEURL + "challenge/updateChallengeCopyNum/" + data.id,
      data,
      configData
    )
    .then((res) => res.data);
}
let completeChallengeCopy = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "challenge/copyChallenge", data, config)
    .then((res) => res);
};

function ChallengeLatestlesson(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/draftlessons/" + userId, configData)
    .then((res) => res.data);
}

let getEmailSettingsForPartner = async () =>{
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(
      BASEURL + "subadmin/getemailsettings/" + subadminId,
      config
    )
    .then((res) =>res.data)
}

let getUnsubscribeEmailUsers = async () => {
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(
      BASEURL + "subadmin/getUnsubscribedUsers/" + subadminId,
      config
    )
    .then((res) =>res.data)
}

let saveEmailSettingsForPartner = async (data) => {
 
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };


return await axios
    .patch(
      BASEURL + "subadmin/emailSettingsForSubadmin/" + subadminId,
      data,
      config
    )
    .then((res) =>res.data)
};
let createEmailSettingForMember = async (data) => {

  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  var formData = new FormData();
  formData.append("emailSubject", data.emailSubject);
  formData.append("emailMessage", data.emailMessage);
  formData.append("emailSchedule", data.emailSchedule);
  
  if (Array.isArray(data.memberAccessLevel)) {
    for (const key of Object.keys(data.memberAccessLevel)) {
      formData.append("memberAccessLevel", data.memberAccessLevel[key]);
    }
  }
  
  if (Array.isArray(data.courseIds)) {
    for (const key of Object.keys(data.courseIds)) {
      formData.append("courseIds", data.courseIds[key]);
    }
  } 
  
  if (Array.isArray(data.individuals)) {
    for (const key of Object.keys(data.individuals)) {
      formData.append("individuals", data.individuals[key]);
    }
  } 
  formData.append('files', data.files);

return await axios
    .post(
      BASEURL + "subadmin/createEmailSettingsForMember/" + subadminId,
      formData,
      config
    )
    .then((res) =>res.data)
};
let getLiveEmailSentToMember = async () => {
 
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
return await axios
    .get(
      BASEURL + "subadmin/getLiveEmailSentBySubadmin/" + subadminId,
      config
    )
    .then((res) =>res.data)
};
let getMemberScheduleEmail = async () => {
 
  let subadminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
return await axios
    .get(
      BASEURL + "subadmin/getScheduleEmailForMember/" + subadminId,
      config
    )
    .then((res) =>res.data)
};
let deleteEmail = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
return await axios
    .delete(
      BASEURL + "subadmin/deleteemail/" + id,
      config
    )
    .then((res) =>res.data)
}




let uploadCourseLesson = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("subadminid", data.subadminid);
  formData.append("lessonsFile", data.lessonsFile);
  return await axios
    .post(BASEURL + "lesson/uploadLesson/", formData, config)
    .then((res) => res);
};

let addCourseLessonDetails = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  var formData = new FormData();
  formData.append("subadminId", data.subadminid);
  formData.append("courseId", data.courseId);
  if (data.moduleId) {
    formData.append("moduleId", data.moduleId);
  }
  formData.append("title", data.title);
  formData.append("goal", data.goal);
  formData.append("description", data.description);
  formData.append("file", data.selectedFile);
  formData.append("schedule", data.schedule);
  formData.append(
    "lessonCompletionRestriction",
    data.lessonCompletionRestriction
  );
  // formData.append("previousLesson", data.previousLesson);
  formData.append("lessonStatus", data.lessonStatus);
  formData.append("hideForMember", data.hideForMember);
  formData.append("lessonPreview", data.lessonPreview);
  if (data?.copyCouLessonNum) {
    formData.append("copyCouLessonNum", data.copyCouLessonNum);
  }
  if(data?.memberAccessLevel)
    {
      for (const key of Object.keys(data.memberAccessLevel)) {
      formData.append("memberAccessLevel", data.memberAccessLevel[key]);
      }
    }
  if (data?.newResource) {
    for (const key of Object.keys(data.newResource)) {
      formData.append("newResource", data.newResource[key]);
    }
  } else if (data?.lessonResourceCopy.length == 1) {
    formData.append("newResource", data.lessonResourceCopy);
  } else if (data?.lessonResourceCopy.length > 1) {
    for (const key of Object.keys(data.lessonResourceCopy)) {
      formData.append("newResource", data.lessonResourceCopy[key]);
    }
  }

  return await axios
    .post(BASEURL + "lesson/uploadLesson", formData, config)
    .then((res) => res);
};

function getSubsById(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "subs/" + idd, configData).then((res) => res.data);
}
let deleteMembership = async (id) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "subs/" + id, configData)
    .then((res) => res.data);
};
let updateMembership = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("tier", data.tier);
  formData.append("description", data.description);
  formData.append("subAdminId", data.subAdminId);
  if (data.thumbnailImg) {
    formData.append("thumbnailImg", data.thumbnailImg);
  }
  formData.append("signUpBtnText", data.signUpBtnText);
  formData.append("substype", data.substype);
  formData.append("emailId", data.emailId);
  formData.append("pauseMembershipLevel", data.pauseMembership);
  formData.append("emailContent", data.emailContent);

  if (data.substype == "Paid") {
    formData.append("amountOneTime", data.amountOneTime);
    formData.append("amountMonthly", data.amountMonthly);
    formData.append("amountYearly", data.amountYearly);
    //formData.append('subscriptionTypeId', data.subscriptionTypeId);
  } else {
    formData.append("amountOneTime", "0");
    formData.append("amountMonthly", "0");
    formData.append("amountYearly", "0");
  }

  formData.append("successMessageSubject", data.successMessageSubject);
  formData.append("successMessageDescription", data.successMessageDescription);
  formData.append("renewalReminderSubject", data.renewalReminderSubject);
  formData.append(
    "renewalReminderDescription",
    data.renewalReminderDescription
  );

  return await axios
    .put(BASEURL + "subs/" + data.idd, formData, config)
    .then((res) => res);
};

function LatestlessonOneCourse(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/latest_lessons/one_course/" + idd, configData)
    .then((res) => res.data);
}

function CourseSchedulelessonOneCourse(idd) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/schedule_lessons/one_course/" + idd, configData)
    .then((res) => res.data);
}

function AddPaypalConfig(data) {
  //  const userID = localStorage.getItem("user_id");
  const bearer = "Bearer" + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .post(BASEURL + "subAdmin/addPaypalConfig", data, configData)
    .then((res) => res.data);
}

function AddPatementGatewayConfig(data) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .post(BASEURL + "subAdmin/addPaymentGatwayConfig", data, configData)
    .then((res) => res.data);
}

function GetSalesPage() {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + "subAdmin/salespage/" + userId, configData)
    .then((res) => res.data);
}

function AddSalesPage(data) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "multipart/form-data", Authorization: bearer },
  };

  return axios
    .post(BASEURL + "subAdmin/salespage/" + userId, data, configData)
    .then((res) => res.data);
}

let courseOnSalesPage = async (courseId, data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");

  var formData = new FormData();

  formData.append("subadminId", userId || "");
  formData.append("courseId", courseId);
  formData.append("onSalesPage", data);

  return await axios
    .put(BASEURL + "subAdmin/courseOnSalesPage/" + userId, formData, configData)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error updating sales page:", error);
      throw error;
    });
};
let eventOnSalesPage = async (eventId, data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");

  var formData = new FormData();

  formData.append("subadminId", userId || "");
  formData.append("eventId", eventId);
  formData.append("onSalesPage", data);

  return await axios
    .put(BASEURL + "subAdmin/eventOnSalesPage/" + userId, formData, configData)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error updating sales page:", error);
      throw error;
    });
};

function getAllInvoices(q) {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let query = "";
  if (q) {
    query = "?q=" + q;
  }

  return axios
    .get(BASEURL + "subAdmin/getAllInvoices/" + userId + query, configData)
    .then((res) => res.data);
}

function EditPaypalConfig(data) {
  const userID = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .put(BASEURL + "subAdmin/editPaypalConfig/" + userID, data, configData)
    .then((res) => res.data);
}

function GetPaypalConfig(data) {
  const userID = localStorage.getItem("subadmin_id");
  const bearer = "Bearer" + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + "subAdmin/getPaypalConfig/:id" + data, configData)
    .then((res) => res.data);
}

function GetSubadminAllPaymentGateways() {
  const user_id = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(
      BASEURL + "subAdmin/getAllSubadminPaymentGateways/" + user_id,
      configData
    )
    .then((res) => res.data);
}

function ModifyPaymentGatewayStatus(data) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .put(BASEURL + "subAdmin/updatePaymentGatwayStatus", data, configData)
    .then((res) => res.data);
}
let updateMembershipInfo = async (data) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };

  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  var formData = new FormData();
  formData.append("membershipName", data.membershipName);
  formData.append("logo", data.image);
  formData.append("brandColor", data.brandColor);

  return await axios.put(BASEURL + "subadmin/" + userId, formData, configData);
};

let getRecentContentData = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  // content/getContentDataOnSubadminSide/${getParentContentId}/${refId}

  return axios
    .get(
      BASEURL + "content/getContentDataOnSubadminSide/" + idd + "/" + userId,
      configData
    )
    .then((res) => res.data);
};

// Ended Here

const getSubAdminSubscriptionLimits = async (id?: string) => {
  const subAdminId = id || localStorage.getItem("user_id");
  if (!subAdminId) return Promise.reject(new Error("No subadmin id found"));

  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios.get(BASEURL + "subs/getLimits/" + id, configData);
};
const sendEmail = async (emailData) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: bearer,
    },
  };

  return await axios
    .post(`${BASEURL}emailtemplate/sendemailwithhtml`, emailData, config)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const unenrollCourse = async (studentId, courseId) => {
  if (!studentId || !courseId) {
    console.error("Invalid studentId or courseId", studentId, courseId);
    throw new Error("Invalid studentId or courseId");
  }
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let enrollSocialWallUserId = {
    userid: studentId,
  };
  return await axios
    .put(
      BASEURL + "courses/unenrollbysubadmin/" + courseId,
      enrollSocialWallUserId,
      config
    )
    .then((res) => res.data);
};

let copySubFolder = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "folder/copySubFolder", data, config)
    .then((res) => res);
};
const fetchInvoices = async () => {
  const subAdminId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: bearer,
      "content-type": "application/json",
    },
  };
  try {
    const response = await axios.get(
      `${BASEURL}subadmin/getinvoices/${subAdminId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error syncing invoice:", error);
    throw error;
  }
};
const editChallengeLessonCommentBySubadminId = async (
  lessonId: string,
  commentId: string,
  updatedComment: string
): Promise<any> => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    const response = await axios.put(
      `${BASEURL}chlesson/editchallengelessoncommentbysubadminid/${lessonId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error editing comment:", error.response?.data || error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error; 
  }
};
const editEventCommentBySubadminId = async (
  eventId: string,
  commentId: string,
  updatedComment: string
): Promise<any> => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    const response = await axios.put(
      `${BASEURL}events/editeventcommentbysubadminid/${eventId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error editing comment:", error.response?.data || error.message);
    } else {
     
      console.error("Unexpected error:", error);
    }
    throw error; 
  }
};
const editCourseLessonCommentBySubadminId = async (
  lessonId: string,
  commentId: string,
  updatedComment: string
): Promise<any> => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    const response = await axios.put(
      `${BASEURL}lesson/editcourselessoncommentbysubadminid/${lessonId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error editing comment:", error.response?.data || error.message);
    } else {
     
      console.error("Unexpected error:", error);
    }
    throw error; 
  }
};
const editPostCommentBySubadminId = async (
  postId: string,
  commentId: string,
  updatedComment: string
): Promise<any> => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    const response = await axios.put(
      `${BASEURL}post/editpostcommentbysubadminid/${postId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error editing comment:", error.response?.data || error.message);
    } else {
     
      console.error("Unexpected error:", error);
    }
    throw error; 
  }
};

export {
  sendEmail,
  addSingleMember,
  sendMailActive,
  getRecentContentData,
  updateMembershipInfo,
  copyEventNumberData,
  updatechCourse,
  deleteLessonch,
  CourseSchedulelessonOneCourse,
  LatestlessonOneCourse,
  updateMembership,
  deleteMembership,
  getSubsById,
  addCourseLessonDetails,
  uploadCourseLesson,
  ChallengeLatestlesson,
  challengeDetailsApi,
  recentChallenges,
  scheduledChallenges,
  updateEvents,
  updateAdminForgotPassword,
  CourseSchedulelesson,
  CourseLatestlesson,
  deleteResources,
  updateLessonDetails,
  updatechLessonDetails,
  updateCourse,
  courseLessons,
  postContentUpdate,
  deleteTemplate,
  deleteChallenge,
  subscribefreeupgrade,
  getAllCommentsofEvents,
  saveEventCommentData,
  getPostDetails,
  moduleList,
  channelcommentlist,
  saveChannelcomment,
  getChannelLessonById,
  challengelivelesson,
  challengeschedulelesson,
  challengedraftlesson,
  uploadChallengeLesson,
  addChallengeLessonDetails,
  getTopCourses,
  coursecommentlist,
  saveCoursecomment,
  challengesDetailsApi,
  getTopChallenges,
  registerChallenges,
  getScheduledChallenges,
  getRecentChallenges,
  totalChallenges,
  getLessonById,
  getLessonByIdch,
  getchLessonById,
  deleteLesson,
  deletecourse,
  livelesson,
  schedulelesson,
  draftlesson,
  courseDetailsApi,
  updateChallengeCopy,
  registerCourse,
  getPreviousYearEvent,
  uploadLesson,
  addLessonDetails,
  totalCourses,
  getRecentCourses,
  getScheduledCourses,
  getLessonsByCourseId,
  getTotalUserByCourseId,
  totalLessonCount,
  deleteContent,
  deleteFolderById,
  updateFolderById,
  getAllUpcomingEvents,
  saveCommentData,
  eventDetails,
  getAllEvents,
  updateCLessonCopyNum,
  createEvents,
  getRecentContent,
  getscheduledContent,
  getTopContent,
  getContentByFolderId,
  updateAdminPassword,
  updateMember,
  getMemberById,
  deleteMember,
  getAllNotification,
  updateRecords,
  deleteUnsubscribedata,
  updateUnsubscribePolicy,
  updateEngageNotificationStatus,
  updateNotificationStatus,
  updateTerms,
  updateWelcomeNote,
  updateNewsData,
  activeSaSubscription,
  forgotPassword,
  updateCompanyDetails,
  updateSubAdminDetails,
  updateSubAdmin,
  PopularSocialList,
  searchWall,
  updateText,
  updateMedia,
  saveAdminPostLikeData,
  saveAdminPostcommentData,
  savePostLikeData,
  savePostcommentData,
  getAcceptIndividualPayCourses,
  deleteWall,
  deletePost,
  getPostByWallId,
  getSocialWallById,
  LatestSocialList,
  sasubscription,
  dragDropCotent,
  getInviteeUsers,
  audioCommentlist,
  likeaudio,
  saveAudiocomment,
  getcomments,
  savecomment,
  likevideo,
  deleteAudio,
  getTopAudio,
  getscheduledAudio,
  getRecentAudio,
  deleteVideo,
  getRecentVideo,
  getscheduledVideo,
  getTopVideo,
  saveAudioInfo,
  saveVideoInfo,
  getVideoByFolderId,
  getAudioByFolderId,
  uploadVideo,
  uploadAudio,
  getPostList,
  createSocialPost,
  getAllUsers,
  SocialList,
  createSocialWall,
  editSocialWall,
  subscriptionListById,
  subscriptionList,
  getContentById,
  getContent,
  updateContent,
  uploadText,
  uploadMedia,
  postContent,
  getUserByEmailAtPartnerSide,
  templateList,
  saveMembership,
  subscriptionTypeList,
  getAllsubscription,
  subscriptionType,
  register,
  login,
  updateContentCopy,
  subadminregister,
  verifyAccount,
  userInfo,
  updateProfile,
  subscription,
  updateCourseCopy,
  createPaypalTransaction,
  createFolder,
  createSubFolder,
  folderList,
  getSubFolderList,
  importMember,
  createContent,
  updateCourLessonNum,
  updateUserStatus,
  userInfo1,
  updatingEvents,
  deleteEvent,
  getSubAdminSubscriptionLimits,
  completeSubscription,
  getLiveLessons,
  updateSubAdminSubscriptionStatus,
  getStudentsById,
  canSubscribe,
  getAllMemberThatBuyMembership,
  updateContentTempData,
  AddPaypalConfig,
  EditPaypalConfig,
  GetPaypalConfig,
  GetSubadminAllPaymentGateways,
  AddPatementGatewayConfig,
  getAllInvoices,
  ModifyPaymentGatewayStatus,
  GetSalesPage,
  AddSalesPage,
  reinviteSingleMem,
  getActiveUsers,
  socialWallUsers,
  eventAudience,
  completeCourseCopy,
  completeChallengeCopy,
  copyCompleteFolder,
  deleteLessonResources,
  unenrollCourse,
  updateCourseForSalesPage,
  courseOnSalesPage,
  copySubFolder,
  fetchInvoices,
  saveNewLessonOrder,
  deleteChLessonComment,
  deleteCourseLessonComment,
  deleteEventComment,
  deletePostComment,
  editChallengeLessonCommentBySubadminId,
  editEventCommentBySubadminId,
  editCourseLessonCommentBySubadminId,
  editPostCommentBySubadminId,
  getMemberScheduleEmail,
  getLiveEmailSentToMember,
  createEmailSettingForMember,
  saveEmailSettingsForPartner,
  getAcceptIndividualPayEvents,
  updateEventForSalesPage,
  eventOnSalesPage,
  getEmailSettingsForPartner,
  getUnsubscribeEmailUsers,
  deleteEmail,
};
